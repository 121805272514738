import * as React from 'react';
import type { HeadFC } from 'gatsby';
import styled from 'styled-components';
import '../styles/globalSeedDesign.ts';
import '../styles/reset.css';
import { ContainedButton } from '@components/common/Button';
import { bridge } from '@utils/bridge/index';
import { vars } from '@seed-design/design-token';

const IndexPage = () => {
  const handleBack = () => {
    bridge?.closeRouter({});
  };

  return (
    <Wrpper>
      <h1>
        앗! 죄송해요.
        <br />
        이벤트가 종료되었어요.
        <br />
        다음에 다시 참여해주세요.
      </h1>

      <ContainedButton size="small" style={{ width: '200px' }} onClick={handleBack}>
        뒤로가기
      </ContainedButton>
    </Wrpper>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;

const Wrpper = styled.main`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${vars.$scale.color.gray600};

    margin-bottom: 20px;
  }
`;
