(function () {
  if (typeof document !== 'undefined') {
    const el = document.documentElement;
    el.dataset.seed = '';

    const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    if (prefersLight.matches) {
      if ('addEventListener' in prefersLight) {
        prefersLight.addEventListener('change', apply);
      } else if ('addListener' in prefersLight) {
        // @ts-ignore
        prefersLight.addListener(apply);
      }
    } else if (prefersDark.matches) {
      if ('addEventListener' in prefersDark) {
        prefersDark.addEventListener('change', apply);
      } else if ('addListener' in prefersDark) {
        // @ts-ignore
        prefersDark.addListener(apply);
      }
    }

    function apply() {
      el.dataset.seedScaleColor = prefersDark.matches ? 'dark' : 'light';
    }

    apply();
  }
})();
